<template>
  <v-row
    justify="space-between"
    class="head-search ma-0">
    <v-col md="2">
      <v-select
        v-model="filter.campaign"
        :items="campaigns"
        label="ช่องทาง"
        outlined
        hide-details
        dense />
    </v-col>
    <v-col md="2">
      <v-select
        v-model="filter.status"
        :items="statuses"
        label="สถานะ"
        outlined
        hide-details
        dense />
    </v-col>
    <v-spacer />
    <v-col md="2">
      <v-text-field
        v-model="filter.search"
        dense
        label="ค้นหา"
        hide-details
        outlined
        @keydown.enter="filterClicked" />
    </v-col>
    <v-col md="1">
      <v-btn
        color="primary"
        class="filter-btn"
        @click="filterClicked()">
        ค้นหา
      </v-btn>
    </v-col>
    <v-col
      offset-md="10"
      md="2">
      <v-spacer />
      <v-btn
        block
        color="primary"
        :to="{ name: 'CreatePromotion'}">
        สร้างโค้ด
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      searching: null,
      statuses: [
        { text: 'ทั้งหมด', value: '' },
        { divider: true },
        { text: 'ใช้งานแล้ว', value: 'used' },
        { text: 'ยังไม่ใช่งาน', value: 'unuse' },
        { text: 'ปิดการใช้งาน', value: 'inactive' }
      ],
      campaigns: [
        { text: 'ทั้งหมด', value: 'all' },
        { divider: true },
        { text: 'แบรนด์', value: 'brand_campaign' },
        { text: 'VIP', value: 'member_campaign' },
        { text: 'ซื้อครั้งแรก', value: 'first_online_purchase' },
        { text: 'Super Campaign', value: 'super_campaign' }
      ]
    }
  },
  computed: {
    filter () {
      return this.value
    }
  },
  methods: {
    filterClicked () {
      this.$emit('filter-clicked', this.filter)
    }
  }
}
</script>
