<template>
  <v-container
    class="products-list-page"
    fluid>
    <promotion-header
      v-model="filter"
      @filter-clicked="filterClicked($event)" />
    <v-row class="products-row">
      <v-col cols="12">
        <promotions-list
          v-model="query"
          :loading="promotionLoading"
          :items="promotions"
          @edit="editClicked($event)"
          @delete="deleteClicked($event)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionProvider from '@/resources/PromotionProvider'
import PromotionHeader from '../components/PromotionHeader.vue'
import PromotionsList from '../components/PromotionsList.vue'

const PromotionService = new PromotionProvider()

export default {
  components: {
    PromotionHeader,
    PromotionsList
  },
  data () {
    return {
      promotions: [],
      promotionLoading: false,
      filter: {
        campaign: 'all',
        status: '',
        search: ''
      },
      query: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      }
    }
  },
  watch: {
    query: {
      handler () {
        this.getPromotions()
      },
      deep: true
    }
  },
  mounted () {
    this.getPromotions()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    filterClicked (filter) {
      this.filter = filter
      this.getPromotions()
    },
    editClicked (promotion) {
      this.$router.push({ name: 'EditPromotion', params: { id: promotion.id } })
    },
    async deleteClicked (promotion) {
      this.setModal({
        value: true,
        title: 'Delete promotion',
        message: 'Do you want to delete this promotion?',
        confirmText: 'Confirm',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: async () => {
          await this.removePromotion(promotion.id)
          await this.getPromotions()
        }
      })
    },
    async removePromotion (promotionId) {
      try {
        this.promotionLoading = true
        await PromotionService.removePromotion(promotionId)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[DELETE PROMOTIONS ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.promotionLoading = false
        this.setSnackbar({
          value: true,
          message: 'ลบรหัสโปรโมชั่นสำเร็จ',
          type: 'success'
        })
      }
    },
    async getPromotions () {
      try {
        this.promotionLoading = true
        const { data } = await PromotionService.getAllPromotionsByPaginate({ ...this.query, ...this.filter })
        this.promotions = data.results
        this.query.total = data.total
        this.query.pages = data.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING PROMOTIONS ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.promotionLoading = false
      }
    }
  }
}
</script>
