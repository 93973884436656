var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.paginate,"server-items-length":_vm.paginate.total,"footer-props":_vm.dataTableFooter},on:{"update:options":function($event){_vm.paginate=$event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" "),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.code),expression:"item.code",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onCopyError),expression:"onCopyError",arg:"error"}],attrs:{"icon":"","x-small":""}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.discountCompute(item))+" ")]}},{key:"item.minPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showFullPriceFormat(item.minPrice))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateCompute(item.dateRange.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateCompute(item.dateRange.endDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusCompute(item.status))+" ")]}},{key:"item.campaignType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCampaign(item.campaignType))+" ")]}},{key:"item.usedCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.usedCountCompute(item))+" ")]}},{key:"item.broadcast",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.broadcastClicked(item)}}},[_c('v-icon',[_vm._v("mdi-message")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editClicked(item)}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteClicked(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }